import axios from 'axios';
import config from '@/config/index'
const isNewVersion = () => {
    let href = window.location.href;   
    if (href.startsWith('http://192.168.1') || href.startsWith('https://192.168.1')) {        
        return
    }
    let url = config.apiUrl + `/version.json?t=${new Date().getTime()}`
    console.log('url:' + url)
    axios.get(url).then(response => {
        if (response.status === 200) {
            console.log('当前版本:', response.data.store)
            let urlVersion = response.data.store;
            let localRestaurantVersion = localStorage.getItem('businessRestaurantVersion');
            if (localRestaurantVersion && localRestaurantVersion != urlVersion) {
                localStorage.setItem('businessRestaurantVersion', urlVersion);
                window.location.reload(true);
                return;
            } else {
                localStorage.setItem('businessRestaurantVersion', urlVersion);
            }
        }
    }).catch(error => {
        console.error('获取后端版本号失败:', error);
    });
}
export default {
    isNewVersion
}