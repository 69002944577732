<template>
  <Layout :style="'min-height:'+ innerHeight + 'px'" class="all-move">
    <div style="position: relative;width: 100%;" v-if="!isChrome">
      <div class="tips-error">
        为了更好的用户体验建议使用 &nbsp;&nbsp;&nbsp;&nbsp;<a :href="config.apiUrl+'/google/ChromeSetup.exe'" style="color:#3a84ff;cursor:pointer;">谷歌浏览器(点击下载)</a>
        <div class="closeChrome" @click="closeChrome"> <img style="width:10px;height:10px" src="../../assets/close.png" /> </div>
      </div>
    </div>
    <Header class="all-heard">
      <div class="layout-logo">互联人如云</div>
      <div class="menu-list">
        <div class="menu-v"
             :class="activeIndex === item.src ? 'active' : ''"
             v-for="(item,index) in topPath"
             :key="index"
             @click="goPath(item.src)"
        >
          {{item.title}}
          <div :class="activeIndex === item.src ? 'DW_num' : 'DW_num_no'" v-if="item.src == '/admin/order' && order_untreated.order_untreated">
            {{order_untreated.order_untreated}}
          </div>
        </div>
      </div>
      <div class="layout-nav">
        <MenuItem style="display: flex;align-items: center" name="userName">
          <div class="bg-circle">{{ userInfo.account?userInfo.account.substring(0,1):'' }}</div><span style="color:#515A6E;width: 90px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ userInfo.account }}</span>
        </MenuItem>
        <div class="show-menu">
          <div @click="logout">退出登录</div>
        </div> 
        <div class="show-msg" v-if="this.userInfo.level === 2" @click="seeContent">
          <div class="red" v-if="msgNumber >0">{{ msgNumber }}</div>
          <Icon type="ios-notifications-outline" size="26"></Icon>
        </div>
      </div>
    </Header>
      
    <Content :style="{minHeight: '280px'}">
      <router-view></router-view>
    </Content>
    <password ref="Password"></password>
  </Layout>
</template>
<script>
import {getUntreated} from '@/api/order'
import {removeCookies} from '@/utils/helper'
import {windowsRise} from '@/utils/dom';
import password from '../changePassword/password'
import WebSocket from '@/utils/websocket';

export default {
  data() {
    return {
      isChrome:false,
      innerHeight: 0,
      openNames: [],
      timer:null,
      activeIndex:'/admin',
      topPath:[
        // {
        //   name:'首页',
        //   path:'/admin'
        // },
        // {
        //   name:'订单',
        //   path:'/admin/order'
        // },
        // {
        //   name:'商品',
        //   path:'/admin/customer'
        // },
        // {
        //   name:'用户',
        //   path:'/admin/user'
        // },
        // {
        //   name:'营收',
        //   path:'/admin/money'
        // },
        // {
        //   name:'应用',
        //   path:'/admin/setting'
        // },
      ]
    }
  },
  components: {
    password
  },
  computed: {
    userInfo(){
      return this.$store.state.userInfo
    },
    order_untreated(){
      return this.$store.state.order_untreated
    }
  },
  mounted() {
    this.isChrome = window.navigator.userAgent.indexOf("Chrome") > -1;
    // console.log('首次进入');
    this.topPath = this.$store.state.menu
    this.activeIndex = this.$route.path
    this.getUntreated()
    this.restHeight();
    windowsRise(window, this.restHeight);
    // console.log(WebSocket)
    WebSocket.init(this.getUntreated);
  },

  methods: {
    closeChrome(){
      this.isChrome = true
    },
    getUntreated(){
      getUntreated().then(res=>{
        this.$store.commit('setOrderUntreated',res.data)
      })
    },
    changePassword() {
      this.$refs.Password.open('123')
    },
    goPath(path){
      this.$store.commit('setActiveIndex', 0)
      this.$router.push({path: path})
      this.activeIndex = path
    },
    /**修改高度 */
    restHeight() {
      this.innerHeight = window.innerHeight - 2;
    },
    /**修改导航展开样式 */
    selectMenu() {
      this.openNames = [this.$route.name.split('-')[0]];
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
      })
    },
    logout() {
      clearInterval(this.timer);
			this.timer = null;
      this.$store.dispatch("resetAction");
      this.$store.dispatch("resetMen");
      removeCookies('hlzw_canyin_token')
      this.$router.replace({path: '/'})
    },
    beforeDestroy() {
      clearInterval(this.timer);
			this.timer = null;
    },
  }

}
</script>

<style>
.layout {
  background: #f5f7f9;
  position: relative;
  overflow: hidden;
}

.all-move {
  padding-top: 64px;
  box-sizing: border-box;
}

.DW_num{
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border: 1px solid #FFC7A3;
  background: #FF914A;
  color: #fff;
}

.DW_num_no{
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border: 1px solid #FF761D;
  background: #FF761D;
  color: #fff;
}

.all-heard {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
}

.menu-list {
  display: flex;
  align-items: center;
  margin-left: 150px;
}

.menu-v {
  position: relative;
  color: #686868;
  font-size: 16px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-v:hover{
  background: #FFA062;
  color: #fff;
}
.menu-v.active {
  background: #FF761D;
  color: white;
}

.layout-logo {
  width: 200px;
  height: 30px;
  text-shadow: 2px 3px 1px rgb(190, 188, 188);/*添加字体文字的阴影*/
  border-radius: 3px;
  float: left;
  position: relative;
  top: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #343434;
  font-size: 20px;
  font-weight: bold;
}

.layout-nav {
  width: 140px;
  margin: 0 auto;
  margin-right: 20px;
  position: relative;
}
.layout-nav:hover .show-menu {
  display: inline-block;
}
.show-msg {
  position: absolute;
  top: 5px;
  left: 32px;
  z-index: 999999999;
  color: #F5F5F5;
  cursor: pointer;
}
.show-msg .red {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 2px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

.ivu-menu-item {
  color: #555555 !important;
}

.show-menu {
  display: none;
  position: absolute;
  width: 140px;
  height: 60px;
  /* right: 30px; */
  top: 60px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #ebeef5;
  text-align: center;
}

.show-menu div {
  cursor: pointer;
}

.show-menu div:hover {
  background: #d7dde4;
}

.ivu-menu-horizontal .ivu-menu-item {
  float: right !important;
}

.bg-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FF761D;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.ivu-menu-dark {
  background: white !important;
}

.ivu-layout-header {
  background: white !important;
  padding: 0 !important;
  border-bottom: 2px solid #f3f3f3;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
  color: #ffffff !important;
  background: #FF761D !important;
  z-index: 2;
}

.ivu-menu-vertical .ivu-menu-item:hover, .ivu-menu-vertical .ivu-menu-submenu-title:hover {
  color: #FF761D !important;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {
  content: '';
  display: block;
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ff5c11 !important;
}


.tips-error{
    transform: translate(-50%);
    position:absolute; 
    top: -50px;
    left:50%;
    z-index:999;
    padding:20px 120px;
    background-color: #373c46;
    color:#fff;
    font-size: 18px;
    border-radius: 10px;
}
.closeChrome{
  position: absolute;right:10px;top:0;
  cursor: pointer;
}
</style>
